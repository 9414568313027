import CssBaseline from '@material-ui/core/CssBaseline';
import { useContext } from 'react';
import Copyright from '../components/assets/Copyright';
import NoPermission from '../components/assets/NoPermission';
import PageHome from '../components/home/PageHome';
import Dashboard from '../components/navigation/Dashboard';
import UserContext from '../src/UserContext';
import {
  getUserPermissions,
  getUserType,
} from '../public/authentication/authentication';

export default function Medias() {
  let { keycloakstate } = useContext(UserContext);

  return (
    <div>
      <CssBaseline />
    {(getUserType(keycloakstate) == 'admin' ||
        getUserType(keycloakstate) == 'chamberUser') &&
      getUserPermissions(process.env.administratorRole) == 'roleEnabled' ? (  
        <Dashboard>
          <PageHome />
          <Copyright />
        </Dashboard>
      ) : (
        <Dashboard>
          <NoPermission />
        </Dashboard>
      )}
    </div>
  );
}
